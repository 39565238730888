import React, { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import { register, registerConfirm } from '../../requests/auth.request';
import { IUser, RegisterStep } from '../../interfaces/auth.interface';
import logo from '../../assets/images/logo.svg';
import { ReactComponent as Congrats } from '../../assets/images/congrats.svg';
import { Headline } from '../../components/headline.component';
import { Text } from '../../components/text.component';
import { Button } from '../../components/button.component';
import Input from '../../components/input.component';
import Components, { LeftColumn, WhiteBox, Form, DoneWrapper } from './register.style';
import { useAuth } from '../../contexts/auth.context';
import CircleLoader from '../../components/circle-loader';
import PrivacyPolicy from '../../assets/files/policy.pdf';  
import { StyledLink } from '../../components/link.component';

export const RegisterPage = () => {
  const { t } = useTranslation();
  const auth = useAuth();
  const navigate = useNavigate();
  const [step, setStep] = useState<RegisterStep>('register');
  const [error, setError] = useState<boolean>(false);
  const [emailError, setEmailError] = useState<string>('');
  const [nameError, setNameError] = useState<string>('');
  const [passwordError, setPasswordError] = useState<string>('');
  const [isPasswordsSame, setIsPasswordsSame] = useState<boolean>(false);
  const [codeError, setCodeError] = useState<string>('');
  const [code, setCode] = useState<string>('');
  const [repeatPassword, setRepeatPassword] = useState<string>('');
  const [form, setForm] = useState<IUser>({
    email: '',
    password: '',
    name: '',
  });
  const [registerLoading, setRegisterLoading] = useState<boolean>(false);

  const [searchParams, setSearchParams] = useSearchParams();

  const handleChange = (key: keyof IUser) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setForm((prevState) => ({ ...prevState, [key]: value }));
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setError(false);
    setIsPasswordsSame(false);
    setNameError('');
    setEmailError('');
    setPasswordError('');

    const isValidEmail = /^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/.test(form.email);
    const isCiryllicPassword = /[а-яА-Я]/g.test(form.password);
    if (!form.name) {
      setNameError('Заполните ФИО');
      return;
    }
    if (!form.email || !isValidEmail) {
      setEmailError('Введите валидный email');
      return;
    }
    if (isCiryllicPassword) {
      setPasswordError('Пароль должен содержать латинские буквы');
      setIsPasswordsSame(false);
      return;
    }
    if (repeatPassword !== form.password) {
      setIsPasswordsSame(true);
      setPasswordError('');
      return;
    }
    switch (step) {
      case 'register': {
        handleRegister();
        break;
      }
      case 'confirm': {
        handleRegisterConfirm();
        break;
      }
    }
  };

  const handleRegister = () => {
    setRegisterLoading(true);
    register({ ...form, email: form.email.toLocaleLowerCase() })
      .then(() => {
        setStep('confirm');
        setRegisterLoading(false);
      })
      .catch(() => {
        setError(true);
        setRegisterLoading(false);
      });
  };

  const handleRegisterConfirm = () => {
    registerConfirm({ email: form.email, code })
      .then(() => {
        setStep('done');
        setTimeout(() => auth.signIn(form, () => navigate('/', { replace: true })), 5000);
      })
      .catch((error) => setCodeError(error.response.data.msg));
  };

  return (
    <Components.Wrapper>
      {step === 'register' && (
        <>
          <LeftColumn>
            <img src={logo} alt={'mark it'} />

            <div className="df">
              <WhiteBox>
                <Headline>{t('register.title')}</Headline>
                <Form onSubmit={handleSubmit}>
                  <Text color={'gray'} fontSize="14px">
                    {t('form.required')}
                  </Text>
                  <Input
                    label="ФИО"
                    type="name"
                    error={!!nameError}
                    errorText={nameError}
                    value={form.name}
                    onChange={handleChange('name')}
                    showButtons={false}
                  />
                  <Input
                    label="Email"
                    type="email"
                    error={!!emailError}
                    errorText={emailError}
                    value={form.email}
                    onChange={handleChange('email')}
                    showButtons={false}
                  />
                  {error && <Text color="black">{t('register.emailexist')}</Text>}
                  <Input
                    label="Пароль"
                    type="password"
                    minlength={5}
                    error={!!passwordError}
                    errorText={passwordError}
                    value={form.password}
                    onChange={handleChange('password')}
                    showButtons={false}
                  />
                  <Input
                    label="Повторите пароль"
                    name="repeatPassword"
                    type="password"
                    error={!!isPasswordsSame}
                    errorText={'Пароли не совпадают. Проверьте правильность.'}
                    value={repeatPassword}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setRepeatPassword(e.target.value)
                    }
                    showButtons={false}
                  />
                  <Button type="submit">
                    {registerLoading ? <CircleLoader /> : t('register.action')}
                  </Button>
                  <Components.PrivacyPolicy>
                    <Trans i18nKey="register.privacyPolicy">
                      Нажимая на кнопку, вы даете согласие на обработку персональных данных и
                      соглашаетесь c
                      <a href={PrivacyPolicy} target="_blank" rel="noreferrer">
                        политикой конфиденциальности
                      </a>
                    </Trans>
                  </Components.PrivacyPolicy>
                </Form>
              </WhiteBox>

              <StyledLink link='/login' text={t('register.alreadyRegistered')} />
            </div>

          </LeftColumn>
        </>
      )}
      {step === 'confirm' && (
        <>
          <img
            src={logo}
            alt={'mark it'}
            height={'62.04px'}
            width={'98.18px'}
            style={{ position: 'absolute', left: '10px' }}
          />
          <WhiteBox>
            <Headline>{t('register.title')}</Headline>
            <Form onSubmit={handleSubmit}>
              <Input
                label="Код подтверждения"
                type="code"
                value={code}
                error={!!codeError}
                errorText={codeError}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setCode(e.target.value)}
                showButtons={false}
              />
              <Text style={{ wordWrap: 'break-word' }}>
                {t('register.confirm', { email: form.email })}
              </Text>
              <Button type="submit">
                {t('register.action')}
              </Button>
            </Form>
          </WhiteBox>
        </>
      )}
      {step === 'done' && (
        <>
          <img
            src={logo}
            alt={'mark it'}
            height={'62.04px'}
            width={'98.18px'}
            style={{ position: 'absolute', left: '10px' }}
          />
          <WhiteBox>
            <Headline>{t('register.title')}</Headline>
            <DoneWrapper>
              <Congrats />
              <Text>{t('register.done')}</Text>
            </DoneWrapper>
          </WhiteBox>
        </>
      )}
    </Components.Wrapper>
  );
};
