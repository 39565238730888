interface PartnerLink {
  name: string;
  link: string;
  id?: number;
};

type PeriodType = 'BY_MONTH' | 'BY_WEEK' | 'BY_PERIOD';

interface GraphData {
  [key: string]: GraphDataItem[]
}

interface GraphDataItem {
  startDate: number,
  endDate: number,
  count: number
  linkName: string
}

export enum RequestStatusType {
  NOT_SENT = 'NOT_SENT',
  IN_PROCESS = 'IN_PROCESS',
  APPROVED = 'APPROVED',
}

export type {
  PartnerLink,
  PeriodType,
  GraphData,
  GraphDataItem,
}