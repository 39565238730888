import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { BasicLayout } from '../layout/basic.layout';
import { RegisterPage } from './register/register.page';
import { LoginPage } from './login/login.page';
import PartnerPage from './partner/partner.page';
import Links from './links/links.page';
import Finance from './finance/finance.page';
import Profile from './profile/profile.page';
import { Chart, registerables, Colors } from 'chart.js';
import { ResetPassword } from './reset-password/reset-password.page';
import { ChangePassword } from './change-password/change-password.page';
Chart.register(...registerables, Colors);

export default function RoutesIndex() {
  return (
    <Routes>
      <Route path="/login" element={<LoginPage />} />
      <Route path="/register" element={<RegisterPage />} />
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route path="/changePassword" element={<ChangePassword />} />

      <Route path="/" element={<BasicLayout />}>
        <Route index element={<Navigate to="/partner/links" replace />} />
        <Route path="/partner" element={<PartnerPage />}>
          <Route index element={<Navigate to="/links" replace />} />
          <Route path="links" element={<Links />} />
          <Route path="finance" element={<Finance />} />
          <Route path="profile" element={<Profile />} />
        </Route>
      </Route>
    </Routes>
  );
}
