import React from 'react'
import { useTranslation } from 'react-i18next';
import { CustomButton, FoldersWrapper, Wrapper } from './partner.style';
import { Outlet, useNavigate } from 'react-router-dom';

const tabs = ['links', 'finance', 'profile']

const PartnerPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Wrapper>
      <FoldersWrapper>
        <div>
          {tabs.map((item, idx) => (
            <CustomButton
              key={idx}
              active={location.pathname.includes(item)}
              onClick={() => navigate(item)}
            >
              {t(`partner.${item}`)}
            </CustomButton>
          ))}
        </div>
      </FoldersWrapper>
      <Outlet />
    </Wrapper>
  )
}

export default PartnerPage