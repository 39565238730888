import React, { ButtonHTMLAttributes } from 'react';
import styled, { css } from 'styled-components';

interface IRoundBtnProps extends ButtonHTMLAttributes<any> {
  children?: any;
  icon?: string;
  btnStyle?: string;
  position?: string;
  isActive?: boolean;
}

export const RoundBtn = styled.button<{ btnStyle?: string, isActive?: boolean }>`
  min-width: 50px;
  min-height: 50px;
  max-width: 50px;
  max-height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(p) => p.theme.lightGray2};
  border-radius: 100px;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.375em;
  color: ${(p) => p.theme.black};
  border: 0;
  padding: 0;
  cursor: pointer;
  transition: background-color 250ms linear, color 250ms linear;
  text-decoration: none;
  & > span[class^='icon-'] {
    font-size: 1.25rem;
  }
  &.active,
  &:hover {
    background: ${(p) => p.theme.primary};
    color: ${(p) => p.theme.white};
  }
  &:disabled {
    background: ${(p) => p.theme.lightGray2};
    color: rgba(0, 0, 0, 0.2);
  }
  ${(p) =>
    p.btnStyle === 'primary' &&
    css`
      background: linear-gradient(0deg, rgba(102, 32, 199, 0.4), rgba(102, 32, 199, 0.4)),
        ${p.theme.white};
      color: ${p.theme.primary};
      &:hover {
        background: ${`linear-gradient(0deg, ${p.theme.primary}, ${p.theme.primary}), ${p.theme.white}`};
        color: ${p.theme.white};
      }
    `}

   ${({ isActive }) => isActive && css`
      background: ${(p) => p.theme.primary};
      color: ${(p) => p.theme.white};
   `} 
`;

export const RoundButton = ({ icon, children, title, position = 'bottom', ...rest }: IRoundBtnProps) => {
  return icon ? (
    <RoundBtn type="button" data-tooltip={title} data-tooltip-position={position} {...rest}>
      {icon && <span className={`icon-${icon}`} />}
    </RoundBtn>
  ) : (
    <RoundBtn type="button" data-tooltip={title} data-tooltip-position={position} {...rest}>
      {children}
    </RoundBtn>
  );
};
