import React, { ButtonHTMLAttributes, useRef, useEffect, ReactNode } from 'react';
import styled from 'styled-components';

interface IModalProps extends ButtonHTMLAttributes<any> {
  children?: ReactNode;
  onClose: () => void;
  width?: string;
  height?: string;
}

const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContent = styled.div<{width?: string, height?: string}>`
  position: relative;
  background-color: white;
  padding: 20px;
  border-radius: 16px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  width: ${({ width }) => (width ? width : 'max-content')};
  height: ${({ height }) => (height ? height : 'max-content')};
  max-height: 70%;
  max-width: 90%;
`;

const CloseIcon = styled.div.attrs({
  className: 'icon-plus',
})`
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 25px;
  cursor: pointer;
  transform: rotate(45deg);
`;

export const Modal = ({ children, width, height, onClose }: IModalProps) => {
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);

  return (
    <ModalWrapper>
      <ModalContent ref={modalRef} width={width} height={height}>
        {children}
        <CloseIcon onClick={onClose} />
      </ModalContent>
    </ModalWrapper>
  );
};
