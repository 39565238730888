import { ITheme } from '../interfaces/theme.interface';

export const THEME: ITheme = {
  primary: '#6620C7',
  lightPrimary: '#c2a6e9',
  darkPrimary: '#521a9f',
  black: '#000000',
  lightBlack: 'rgba(0, 0, 0, 0.6)',
  lightBlack2: 'rgba(0, 0, 0, 0.2)',
  white: '#FFFFFF',
  gray: '#666666',
  middleGray: '#999999',
  lightGray: '#CCCCCC',
  lightGray2: '#D9D9D9',
  infoGray: 'rgba(0, 0, 0, 0.4)',
  red: '#C72052',
  lightRed: '#DF2C37',
  darkPrimaryGradient: 'linear-gradient(0deg, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)), #6620C7',
  lightPrimaryGradient:
    'linear-gradient(0deg, rgba(102, 32, 199, 0.4), rgba(102, 32, 199, 0.4)), #FFFFFF',
  grayGradient: 'linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), #FFFFFF',
  gray15: 'rgba(0, 0, 0, 0.15)',
  gray10: 'rgba(0, 0, 0, 0.1)'
};
