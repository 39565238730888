import React, { FC} from 'react';
import { Bar } from 'react-chartjs-2';
import { chartOptions } from '../../contants/chart.constant';

const BarChart: FC<{ data: any, chartRef: any }> = ({ data, chartRef }) => {
  return (
    <div id='chart-box' style={{ height: '21rem' }}>
      <Bar
        data={data}
        options={chartOptions}
        ref={chartRef}
      />
    </div>

  );
};

export default BarChart;
