import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const FoldersWrapper = styled.ul`
  display: flex;
  flex-direction: row;
  list-style: none;
  justify-content: space-between;
  margin: inherit;
  padding: 0;
  & > div {
    display: flex;
    gap: 6px;
  }
  height: 45px;
`;

const CustomButton = styled.button<{ active?: boolean }>`
  background: ${(p) => (p.active ? p.theme.lightPrimaryGradient : p.theme.grayGradient)};
  border-radius: 16px;
  width: max-content;
  height: 45px;
  border: none;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  color: ${(p) => (p.active ? p.theme.primary : p.theme.black)};
  &.active,
  &:hover {
    color: ${(p) => p.theme.primary};
    background: linear-gradient(0deg, rgba(102, 32, 199, 0.4), rgba(102, 32, 199, 0.4)),
      ${(p) => p.theme.white};
  }
  padding: 13px 16px;
  cursor: pointer;

  &:disabled{
    color: ${(p) => p.theme.lightBlack};

    &:hover{
      color: ${(p) => p.theme.lightBlack};
      background: ${(p) => (p.theme.grayGradient)};
      cursor: default;
    }
  }
`;

const WhiteContainer = styled.div`
  width: 100%;
  border-radius: 1rem;
  background: #FFF;
  padding: 1.25rem;
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
  height: 100%;

  .request-button {
    margin-top: 0.625rem;
  }

  .request-button.inactive{
    height: 3.125rem;
    background: none;
    border: 1px solid ${(p) => p.theme.lightBlack2};
    box-shadow: none;
    cursor: default;
  }
`;

export {
  Wrapper,
  FoldersWrapper,
  CustomButton,
  WhiteContainer
}