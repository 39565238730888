import { PartnerLink, PeriodType } from '../interfaces/partner.interace';
import api from './api';

const baseUrl = '/partner';

const generateLink = () => {
  return api.get(`${baseUrl}/link/generate-code`).then(res => res.data);
}

const createLink = (body: PartnerLink) => {
  return api.post(`${baseUrl}/link/create`, body).then(res => res.data);
}

const deleteLink = (id: number) => {
  return api.delete(`${baseUrl}/link/delete?id=${id}`).then(res => res.data);
}

const getLinkList = () => {
  return api.get(`${baseUrl}/link/list`).then(res => res);
}

const updateLink = (link: PartnerLink) => {
  return api.post(`${baseUrl}/link/update`, link).then(res => res.data);
}

const getGraphData = (graphType: string, periodType: PeriodType, start?: Date | null, end?: Date | null) => {

  const startDate = start ? start.toDateString() : '';
  const endDate = end ? end.toDateString() : '';

  const params = periodType === 'BY_PERIOD'
    ? `graphType=${graphType}&periodType=BY_WEEK&startDate=${startDate}&endDate=${endDate}`
    : `graphType=${graphType}&periodType=${periodType}`

  return api.get(`${baseUrl}/graph?${params}`).then(res => res);
}

const downloadGraphData = async (graphType: string, periodType: PeriodType, start?: Date | null, end?: Date | null) => {
  const startDate = start ? start.toDateString() : '';
  const endDate = end ? end.toDateString() : '';

  const params = periodType === 'BY_PERIOD'
    ? `graphType=${graphType}&periodType=BY_WEEK&startDate=${startDate}&endDate=${endDate}`
    : `graphType=${graphType}&periodType=${periodType}`

  return api
    .get(`${baseUrl}/graph/download?${params}`, { responseType: 'blob' })
    .then((blob: any) => {
      if (blob?.size > 0) {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Статистика.xls',);
        document.body.appendChild(link);
        link.click();
      } else {
        console.error('Ошибка при скачивании файла');
      }
    });
}

const sendRequest = (body: any) => {
  return api.post(`${baseUrl}/request/create`, body).then(res => res.data);
}

const getAccount = () => {
  return api.get(`${baseUrl}/account`).then(res => res.data);
}

const getHistory = () => {
  return api.get(`${baseUrl}/request/history`).then(res => res);
}

export {
  createLink,
  deleteLink,
  getLinkList,
  updateLink,
  generateLink,
  getGraphData,
  downloadGraphData,
  sendRequest,
  getAccount,
  getHistory
}