import api from "./api";
import { IUser, ITokenResponse } from "../interfaces/auth.interface";

export const register = (user: IUser): Promise<any> => {
  return api.post('auth/register/partner', user)
};

export const registerConfirm = (dto: { email: string, code: string }): Promise<any> => {
  return api.post('auth/register/confirm', dto)
};

export const logIn = (user: IUser): Promise<ITokenResponse> => {
  return api.post('auth/logIn/partner', user)
};

export const getToken = (refreshToken: string): Promise<any> => {
  return api.post('auth/getToken', { refreshToken })
};

export const forgotPassword = (forgotPasswordRequestDto: { email: string }): Promise<any> => {
  return api.post('auth/forgotPassword', forgotPasswordRequestDto)
};

export const resetPassword = (resetPasswordData: { email: string, password: string, token: string }): Promise<any> => {
  return api.post('auth/resetPassword', resetPasswordData)
};
