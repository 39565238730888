import React, { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { useAuth } from '../contexts/auth.context';
import { AuthGuard } from '../pages/auth-guard';
import { RoundButton } from '../components/round-btn.component';
import logo from '../assets/images/logo.svg';
import { getUserDetails } from '../requests/user.requests';

const Header = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1.125rem 1.25rem 0.75rem;
`;

const HeaderItem = styled.div`
  display: flex;
  position: relative;
  & > img {
    height: 3.75rem;
    filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.25));
  }
`;

const OutletWrapper = styled.div`
  padding: 1.25rem;
`;

export const BasicLayout = () => {
  const { t } = useTranslation();
  const auth = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [prevPathname, setPrevPathname] = useState<string>(location.pathname);
  const [didMount, setDidMount] = useState(false);
  const [, setSearchParams] = useSearchParams();
  const [initials, setInitials] = useState<string>('');

  useEffect(() => {
    setPrevPathname(prevPathname);
    if (didMount && prevPathname !== '/') {
      setSearchParams({});
      setSearchQuery('');
    }
  }, [didMount]);

  useEffect(() => {
    getUserDetails().then((res) => {
      const firstName = res.name?.split(' ')[1];
      const surname = res.name?.split(' ')[0];

      const initials = `${firstName ? firstName?.charAt(0) : ''}${surname ? surname?.charAt(0) : ''
        }`.toUpperCase();
      setInitials(initials);
    });
  }, []);

  useEffect(() => {
    if (searchQuery) {
      setSearchParams({ q: searchQuery as string });
    } else if (didMount) {
      setSearchParams({});
    }
  }, [searchQuery, setSearchParams]);

  useEffect(() => setDidMount(true), []);


  const handleLogOut = () => {
    auth.signOut(() => navigate('/'));
  };

  const handleNavigateToProfile = () => {
    navigate('/partner/profile');
  };

  return (
    <AuthGuard>
      <>
        <Header>
          <HeaderItem>
            <img src={logo} alt={'mark it'} />
          </HeaderItem>
          <HeaderItem
            style={{ gap: '10px', display: 'flex', justifyContent: 'end', width: '100%' }}
          >
            <RoundButton isActive={location.pathname.includes('profile')} title={t('main.profile') as string} onClick={handleNavigateToProfile}>
              {initials}
            </RoundButton>
            <RoundButton
              icon={'log-out'}
              title={t('main.logOut') as string}
              onClick={handleLogOut}
            />
          </HeaderItem>
        </Header>

        <OutletWrapper>
          <Outlet />
        </OutletWrapper>
      </>
    </AuthGuard>
  );
};
