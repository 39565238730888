import styled from 'styled-components';
import { Headline } from '../../components/headline.component';
import { Text } from '../../components/text.component';

export const Wrapper = styled.div`
  height: 100vh;
  display: flex;

  .df {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
    align-items: center;
  }

  & > ${Headline}, ${Text} {
    color: ${p => p.theme.white};
  };
  & > ${Headline} {
    margin-bottom: 0.625rem;
  }
`

export const SparkleWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  margin-top: 1.875rem;
`;

const ColumnTemplate = styled.div`
  position: relative;
  min-height: 1px;
  display: grid;
`;

export const ColumnContent = styled.div`
  display: flex;
  width: 360px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`

export const RightColumn = styled(ColumnTemplate)`
  flex: 0 0 100%;
  max-width: 100%;
  justify-content: center;
  align-content: center;

  & > img {
    position: absolute;
    top: 13px;
    left: 20px;
    height: 3.75rem;
    filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.25));
  }
`;

export const WhiteBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #FFFFFF;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
  border-radius: 16px;
  width: 380px;
  height: max-content;
  padding: 20px;
  gap: 20px;
`
export const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  gap: 20px;
  width: 100%;
`

export const ErrorWrapper = styled.div`
  & > ${Text} {
    color: ${p => p.theme.black};
  };
  display: flex;
  flex-direction: column;
`

const components = {
  Wrapper,
  SparkleWrapper,
  RightColumn,
  Form,
  ErrorWrapper
};

export default components;
