import React, { FC, useState } from 'react'
import { WhiteContainer } from '../pages/partner/partner.style'
import { AddLinkButton, ButtonsWrap, LinkItem, LinksContainer, LinksPageModalContainer, PartnerLinkContainer } from '../pages/links/links.style'
import { Text } from './text.component'
import { Modal } from './modal.component'
import Input from './input.component'
import { Button } from './button.component'
import { PartnerLink } from '../interfaces/partner.interace'
import { useTranslation } from 'react-i18next'
import { createLink, deleteLink, generateLink, updateLink } from '../requests/partner.request'
import CircleLoader from './circle-loader'

interface IProps {
  links: PartnerLink[];
  updateList: () => void;
}

const LinksList: FC<IProps> = ({ links, updateList }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [error, setError] = useState<boolean>(false);
  const [selectedLink, setSelectedLink] = useState<PartnerLink | null>();
  const [isCreating, setIsCreating] = useState<boolean>(false);
  const [copySuccess, setCopySuccess] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleEdit = (link: PartnerLink) => () => {
    setIsCreating(false);
    setError(false);
    setIsOpen(true);
    setSelectedLink(link);
  };

  const openDeleteModal = (item: PartnerLink) => {
    setSelectedLink(item);
    setIsDeleteOpen(true);
  }

  const handleDelete = () => {
    selectedLink?.id && deleteLink(selectedLink.id).then(() => {
      setSelectedLink(null);
      setIsDeleteOpen(false);
      updateList();
    })
  };

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text)
      .then(() => {
        setCopySuccess(true);
        setTimeout(() => {
          setCopySuccess(false);
        }, 2000);
      })
  };

  const openCreateLinkModal = () => {
    setIsCreating(true);
    setError(false);
    generateLink().then((res) => {
      let linkName = `Ссылка ${links.length + 1}`;
      let i = 1;
      while (links.find(link => link.name === linkName)) {
        linkName = `Ссылка ${links.length + i}`;
        i++;
      }

      setSelectedLink({ name: linkName, link: res });
      setIsOpen(true);
    })
  }

  const handleCancel = () => {
    isOpen && setIsOpen(false);
    isDeleteOpen && setIsDeleteOpen(false);
    setSelectedLink(null);
  }

  const handleSubmit = () => {
    if (isLoading) {
      return;
    }

    const linkNames = links.map(link => link.name);

    if (selectedLink?.name && linkNames.includes(selectedLink.name)) {
      const foundId = links.find(link => link.name === selectedLink.name)?.id;
      if (foundId && foundId !== selectedLink.id) {
        setError(true);
        return;
      }
    }

    setError(false);

    const request = isCreating ? createLink : updateLink;
    setIsLoading(true);
    selectedLink
      && request(selectedLink)
        .then(() => {
          setIsCreating(false);
          updateList();
          setIsOpen(false);
        })
        .finally(() => {
          setIsLoading(false);
        })
  }

  return (
    <WhiteContainer>
      <Text fontSize="1.4375rem" strong> {t('partner.my-links')}</Text>

      {links.length > 0 && (
        <LinksContainer>
          {
            links.map(link => (
              <LinkItem key={link.name}>
                <Text>{link.name}</Text>
                <div className="menu">
                  <span
                    className='icon-copy'
                    onClick={() => copyToClipboard(link.link)}
                    data-tooltip={copySuccess ? t('tooltip.copy-link-success') : t('tooltip.copy-link')}
                    data-tooltip-position="bottom"
                  />
                  <span
                    className='icon-pencil'
                    onClick={handleEdit(link)}
                    data-tooltip={t('partner.edit-name')}
                    data-tooltip-position="bottom-right"
                  />
                  <span
                    className='icon-trash'
                    onClick={() => openDeleteModal(link)}
                    data-tooltip={t('tooltip.delete-link')}
                    data-tooltip-position="bottom-right"
                  />
                </div>
              </LinkItem>
            ))
          }
        </LinksContainer>
      )}

      <AddLinkButton onClick={openCreateLinkModal} disabled={isOpen || links.length > 9}>
        <Text fontWeight={600}> {t('partner.new-link')}</Text>
        <span className='icon-plus' />
      </AddLinkButton>

      {
        isOpen && <Modal
          onClose={handleCancel}
          width='23.75rem'
        >
          <LinksPageModalContainer>
            <Text fontWeight={600} fontSize='1.875rem'> {isCreating ? t('partner.new-link') : t('partner.edit-name')}</Text>

            <Input
              value={selectedLink?.name}
              onChange={(e) => selectedLink && setSelectedLink({ ...selectedLink, name: e.target.value })}
              label={t('partner.link-title')}
              error={error}
              errorText={t('error.name-exists') as string}
              showButtons={false}
            />

            <PartnerLinkContainer>
              <Text small>{t('partner.your-link')}</Text>
              <div className='link'>
                <Text>{selectedLink && selectedLink.link}</Text>
                <span
                  className='icon-copy'
                  data-tooltip={copySuccess ? t('tooltip.copy-link-success') : t('tooltip.copy-link')}
                  onClick={() => selectedLink && copyToClipboard(selectedLink.link)}
                  data-tooltip-position="bottom"
                />
              </div>
            </PartnerLinkContainer>

            <ButtonsWrap>
              <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <Button btnStyle={'cancel'} onClick={handleCancel}>
                  {t('button.cancel')}
                </Button>
                <Button
                  icon={isLoading ? '' : isCreating ? 'plus' : 'check'}
                  onClick={handleSubmit}
                  disabled={selectedLink?.name?.length === 0}
                  style={{ width: '9.5rem' }}
                >
                  {isLoading ? <CircleLoader /> : isCreating ? t('button.create') : t('button.save')}
                </Button>
              </div>
            </ButtonsWrap>
          </LinksPageModalContainer>
        </Modal>
      }

      {
        isDeleteOpen && (
          <Modal
            onClose={handleCancel}
            width='28.75rem'
          >
            <LinksPageModalContainer>
              <Text fontWeight={500}>Вы действительно ходите удалить ссылку?</Text>
              <ButtonsWrap>
                <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                  <Button btnStyle={'cancel'} onClick={handleCancel}>
                    {t('button.cancel')}
                  </Button>
                  <Button
                    icon={'trash'}
                    onClick={handleDelete}
                  >
                    {t('button.delete')}
                  </Button>
                </div>
              </ButtonsWrap>
            </LinksPageModalContainer>
          </Modal>
        )}
    </WhiteContainer>
  )
}

export default LinksList