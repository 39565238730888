import styled from "styled-components";

export const ProfileWrapper = styled.div`
  width: calc(50% - 0.3125rem);

  .profile {
    &__relative {
      width: 100%;
      position: relative;
    }

    &__title {
      display: block;
      flex: 1;
      width: 100%;
      margin: 0;
    }

    &__info {
      background-color: #fff;
      padding: 1.25rem;
      border-radius: 1rem;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      gap: 1.875rem;
      flex-wrap: wrap;
      align-items: flex-start;
      transition: 3s ease-in-out all;
    }

    &__container {
      flex: 2;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 6px 0 0 0;
      gap: 1.875rem;
      transition: 3s ease-in-out all;
    }

    &__tariff {
      font-size: 1rem;
      line-height: 1.375rem;
      font-weight: 500;
      margin: 0;
    }

    &__changePassword {
      width: 100%;
      background-color: rgba(0, 0, 0, 0.06);
      padding: 1.25rem;
      border-radius: 1rem;
      display: flex;
      flex-direction: column;
      gap: 1.25rem;
      transition: 3s ease-in-out all;

      &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      &__icons {
        display: flex;
        gap: 0.375rem;
        align-items: center;
        margin-right: 2px;

        & > span[class^='icon-'] {
          font-size: 1.25rem;
          cursor: pointer;
        }

        span[class='icon-check'] {
          color: #6620C7;
        }

      }

      &__container {
        position: relative;
        width: 100%;
        transition: 3s ease-in-out all;
        display: flex;
        flex-direction: column;
        gap: 0.9375rem;
      }

      &__button {
        border: none;
        color: #000;
        font-size: 1rem;
        line-height: 1.375rem;
        display: block;
        width: fit-content;
        cursor: pointer;
        background-color: transparent;
        font-weight: 600;
        padding: 0;
        position: relative;

        &:hover {
          color: #6620C7;
        }

        &:active {
          color: #6620C7;
        }
      }
    }

    &__inputField {
      @extend .profile__verifyEmail;
      width: 100%;
    }

    &__prompt {
      font-size: 0.875rem;
      line-height: 1.1875rem;
      padding: 0;
      font-weight: 600;
      margin: -5px 0 0 0;

      &--success {
        @extend .profile__prompt;
        color: rgba(102, 32, 199, 1);
      }
    }

    &__verifyEmail {
      display: flex;
      flex-direction: column;
      gap: 0.625rem;
      width: 100%;

      &__description {
        margin: 0;
        font-size: 1rem;
        line-height: 1.375rem;
      }

      &__container {
        padding: 1.25rem;
        background-color: rgba(0, 0, 0, 0.06);
        border-radius: 1rem;
        display: flex;
        flex-direction: column;
        gap: 1.25rem;
      }

      &__prompt {
        font-size: 0.875rem;
        line-height: 1.1875rem;
        padding: 0;
        font-weight: 600;
        margin: -5px 0 0 0;
      }
    }

    label,
    input {
        width: 100%;
    }
  }
`