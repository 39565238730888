import styled from "styled-components";
import { Text } from "../../components/text.component";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.625rem;

  .row {
    display: flex;
    gap: 0.625rem;
  }

  .column {
    display: flex;
    flex-direction: column;
    gap: 0.625rem;
    flex: 1;
  }

  .left {
    flex: 0.3;
    display: flex;
    flex-direction: column;
    gap: 0.625rem;

    .title {
      display: flex;
      gap: 0.3125rem;
      align-items: center;
    }

    .icon {
      display: flex;
      width: 1.25rem;
      height: 1.25rem;
      justify-content: center;
      align-items: center;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.10) 0%, rgba(0, 0, 0, 0.10) 100%), #FFF;
      border-radius: 50%;
      cursor: pointer;
      position: relative;
      
      .info-box {
        display: none;
        position: absolute;
        right: 0;
        bottom: 0;
        transform: translate(100%, 100%);
        width: 18.75rem;
        padding: 0.9375rem 1.25rem;
        border-radius: 1rem;
        background: #FFF;
        box-shadow: 0px 6px 20px 0px rgba(0, 0, 0, 0.15);
      }

      &:hover {
        .info-box {
          display: block;
          z-index: 10;
        }
      }
    }
  }

  .right {
    display: flex;
    flex-direction: column;
    gap: 0.625rem;
    flex: 1;
    .item {
      display: flex;
      gap: 0.3125rem;
      flex-direction: column;
    }
    
    label,
    input {
      width: 100%;
    }
  }

  .earnings {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr;
    row-gap: 0.625rem;

    ${Text} {
      width: 50%;
    }
  }
`;

export {
  Wrapper
}