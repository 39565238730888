import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from './contexts/auth.context';
import { ThemeProvider } from 'styled-components';
import { THEME } from './contants/theme.constants';
import Routes from './pages/index'
import './index.scss'
import './i18n';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <BrowserRouter>
    <AuthProvider>
      <ThemeProvider theme={THEME}>
        <Routes />
      </ThemeProvider>
    </AuthProvider>
  </BrowserRouter>,
);

