import React, { useState, FC, useRef, useEffect } from 'react'
import ChartDate from './chart-date.components'
import { PeriodType } from '../interfaces/partner.interace';
import { WhiteContainer } from '../pages/partner/partner.style';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Text } from './text.component';
import { Button } from './button.component';
import BarChart from './charts/bar.chart';
import { downloadGraphData } from '../requests/partner.request';

const tabs = ['registrations', 'earnings']

const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  & > div {
    display: flex;
    align-items: center;

    &:first-child {
      gap: 1.25rem;
    }

    &:last-child {
      gap: 0.3125rem;
      cursor: pointer;
    }
  }
`;

const ChartContainer = styled.div`
  width: 100%;
  display: flex;
  padding: 1.25rem;
  flex-direction: column;
  gap: 1.875rem;
  border-radius: 1rem;
  background: rgba(102, 32, 199, 0.10);
`;

const ChartHeader = styled.div`
  display: flex;
  justify-content: space-between;
  
  .total {
    font-size: 1rem;
    font-weight: 700;
  }
`;

const Tabs = styled.div`
  display: flex;
  gap: 0.9375rem;

  .tab {
    font-size: 1rem;
    font-weight: 600;
    color: ${(p) => p.theme.infoGray};
    cursor: pointer;
  }

  .tab.active {
    color: ${(p) => p.theme.black};
  }
`;

const LegendGrid = styled.div<{ columnNumber: number }>`
  display: grid;
  width: 100%;
  row-gap: 1.25rem;

  & > div {
    display: flex;
    gap: 0.375rem;
    cursor: pointer;

    &.selected {
      text-decoration: line-through;
      ${Text} {
        color: ${(p) => p.theme.infoGray};
      }
    }
  }

  .circle {
    width: 1.25rem;
    height: 1.25rem;
    border-radius: 50%;
  }

  ${({ columnNumber }) => columnNumber && css`
    grid-template-columns: repeat(${columnNumber}, 1fr);
  `};
`;

interface IProps {
  data: any;
  getData: (graphType: string, periodType: PeriodType, start?: Date | null, end?: Date | null) => void;
  total: number;
  graphType: string;
  setGraphType: (graphType: string) => void
}
const Chart: FC<IProps> = ({ data, getData, total, graphType, setGraphType }) => {
  const { t } = useTranslation();

  const chartRef = useRef<any>(null)

  const [selectedOption, setSelectedOption] = useState<PeriodType>('BY_WEEK');
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [selectedTab, setSelectedTab] = useState(tabs[0]);
  const [legendList, setLegendList] = useState<any[]>([]);

  const handleOptionChange = (selectedOption: PeriodType) => {
    setSelectedOption(selectedOption);
    setEndDate(null);
    if (selectedOption !== 'BY_PERIOD') {
      getData(graphType, selectedOption);
    }
  };

  const handleDateChange = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const handleLabelClick = (e: any, item: any) => {
    if (chartRef.current) {
      const parent = e.target.parentNode;
      parent.classList.toggle('selected');
      const isVisible = chartRef.current.isDatasetVisible(item.datasetIndex);
      chartRef.current.setDatasetVisibility(item.datasetIndex, !isVisible)
      chartRef.current.update();
    }
  };

  const handleDownload = () => {
    downloadGraphData(graphType, selectedOption, startDate, endDate)
  }

  const handleTabChange = (tab: string) => {
    setSelectedTab(tab);
    const graphType = tab === 'registrations' ? 'REGISTRATION' : 'BONUS';
    setGraphType(graphType);
    getData(graphType, selectedOption, startDate, endDate);
  }

  useEffect(() => {
    const chart = chartRef.current;
    if (chart) {
      const list = chart.legend.legendItems;
      setLegendList(list);
    }
  })

  return (
    <WhiteContainer>
      <Header>
        <div>
          <Text strong fontSize='1.4375rem'>{t('statistics')}</Text>
          <ChartDate
            selectedOption={selectedOption}
            startDate={startDate}
            endDate={endDate}
            handleOptionChange={handleOptionChange}
            handleDateChange={handleDateChange}
            getData={getData}
            graphType={graphType}
          />
        </div>

        <Button btnStyle='cancel' onClick={handleDownload}>
          {t('export')}
          <span className='icon-download' />
        </Button>
      </Header>

      <ChartContainer>
        <ChartHeader>
          <Tabs>
            {tabs.map((tab: string) =>
              <Text key={tab} className={tab === selectedTab ? 'tab active' : 'tab'} onClick={() => handleTabChange(tab)}>{t(tab)}</Text>
            )}
          </Tabs>
          <Text strong className="total">{total}</Text>
        </ChartHeader>

        {data && <BarChart data={data} chartRef={chartRef} />}

        <LegendGrid columnNumber={legendList.length > 4 ? 5 : legendList.length}>
          {legendList.map((item: any) => (
            <div key={item.text} onClick={(e) => handleLabelClick(e, item)}>
              <div style={{ backgroundColor: item.fillStyle }} className='circle'></div>
              <Text fontSize='0.875rem' fontWeight={600}>{item.text}</Text>
            </div>
          ))}
        </LegendGrid>

      </ChartContainer>

    </WhiteContainer>
  )
}

export default Chart