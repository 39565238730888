import styled from "styled-components";
import { Text } from "../../components/text.component";

const AddLinkButton = styled.button`
  all: unset;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.3125rem;
  width: max-content;

  span {
    font-size: 1.25rem;
  }

  &:hover {
    color: ${p => p.theme.primary};

    ${Text} {
      color: ${p => p.theme.primary};
    }
  }

  &:disabled {
    opacity: 0.2;

    &:hover {
      color: ${p => p.theme.black};
    }

    ${Text} {
      color: ${p => p.theme.black};
    }
  }
`;

const LinksContainer = styled.ul`
  border-bottom: 1px solid ${p => p.theme.gray15};
  border-top: 1px solid ${p => p.theme.gray15};
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
`;

const LinkItem = styled.li`
  display: flex;
  justify-content: space-between;
  padding: 0.625rem 0.9375rem;
  border-bottom: 1px solid ${p => p.theme.gray15};

  .menu {
    display: none;
    gap: 0.9375rem;
    align-items: center;
    span {
      cursor: pointer;

      &:hover {
        color: ${p => p.theme.primary};
      }
    }
  }

  &:hover {
    background-color: ${p => p.theme.gray10};
    .menu {
      display: flex;
    }
  }

  &:last-child {
    border-bottom: none;
  }
`;

const LinksPageModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
`;

const PartnerLinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.3125rem;

  .link {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    span {
      cursor: pointer;

      &:hover {
        color: ${p => p.theme.primary};
      }
    }
  }
`;

const ButtonsWrap = styled.div`
  display: flex;
  justify-content: space-between;
`;

export {
  AddLinkButton,
  LinksContainer,
  LinkItem,
  LinksPageModalContainer,
  PartnerLinkContainer,
  ButtonsWrap
}