import React, { Fragment, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { WhiteContainer } from '../partner/partner.style'
import { ReactComponent as I } from '../../assets/icons/i.svg'
import { Text } from '../../components/text.component'
import { Wrapper } from './finance.style'
import Input from '../../components/input.component'
import { Button } from '../../components/button.component'
import { getUserDetails } from '../../requests/user.requests'
import { getAccount, getHistory, sendRequest } from '../../requests/partner.request'
import CircleLoader from '../../components/circle-loader'
import { RequestStatusType } from '../../interfaces/partner.interace'

interface IUserData {
  cardNumber: string;
  cardOwnerName: string;
  email: string;
  phoneNumber: string;
}

const text = 'Вы получаете 30% (после вычетов налогов) с каждой первой оплаты сервиса пользователем, который пришел по вашей ссылке и 5% в течении 360 дней по всем последующим покупкам этого пользователя.'
const monthNames = ['января', 'февраля', 'марта', 'апреля', 'мая', 'июня', 'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря']

const Finance = () => {
  const { t } = useTranslation();

  const [total, setTotal] = useState<number | null>(null);
  const [available, setAvailable] = useState<number | null>(null);
  const [buttonText, setButtonText] = useState<string | null>(null);
  const [disabled, setDisabled] = useState(true);
  const [isSent, setIsSent] = useState(false);
  const [status, setStatus] = useState<RequestStatusType | null>(null);
  const [userData, setUserData] = useState<IUserData>({
    cardNumber: '',
    cardOwnerName: '',
    email: '',
    phoneNumber: '',
  })
  const [isLoading, setIsLoading] = useState(false);

  const [earnings, setEarnings] = useState([]);

  const handleChangeValue = (key: string, val: string) => {
    setUserData(prev => ({ ...prev, [key]: val }));
  }

  const handleSendRequest = () => {
    if (isLoading) return;

    setIsLoading(true);
    sendRequest(userData)
      .then(() => {
        updateData();
        setIsSent(true);
      })
      .finally(() => setIsLoading(false));
  }

  const updateData = () => {
    getAccount()
      .then(res => {
        setTotal(res.totalBonusAmount);
        setAvailable(res.remainingAmount);
        setStatus(res.requestStatus as RequestStatusType);
      })

    getHistory()
      .then((res: any) => {
        setEarnings(res.list);
      })
  }

  useEffect(() => {
    if (status === RequestStatusType.IN_PROCESS) {
      setButtonText('Вы отправили заявку')
      setIsSent(true);
    } else if (status === RequestStatusType.APPROVED) {
      const today = new Date().getDate();
      const month = today < 15 ? monthNames[new Date().getMonth()] : monthNames[new Date().getMonth() + 1];
      setButtonText(`Следующий вывод доступен с 15 ${month}`);
      setIsSent(true);
    } else if (status === RequestStatusType.NOT_SENT) {
      if (!available || available === 0) {
        setButtonText('Нет доступных средств для вывода')
        setDisabled(true);
      } else if (userData.email && userData.cardNumber && userData.cardNumber.length >= 12 && userData.cardOwnerName && userData.phoneNumber && available !== null && available > 0 && !isSent) {
        setButtonText('Оставить заявку')
        setDisabled(false);
      } else if (isSent) {
        setButtonText('Вы отправили заявку')
        setDisabled(true);
      } else {
        setButtonText('Оставить заявку')
        setDisabled(true);
      }
    }
  }, [userData.phoneNumber, userData.cardOwnerName, userData.cardNumber, available, isSent, userData.email, status]);

  useEffect(() => {
    getUserDetails()
      .then(res => {
        setUserData((prev: any) => ({ ...prev, email: res.email, phoneNumber: res.phoneNumber }));
      })

    updateData();
  }, []);

  return (
    <Wrapper>
      <div className="row">
        <div className="column">
          <WhiteContainer>
            <div className="row">
              <div className="left">
                <div className="title">
                  <Text fontSize='1.4375rem' strong>{t('finance.title')}</Text>
                  <div className="icon">
                    <I />
                    <div className="info-box">
                      <Text fontSize='0.875rem' fontWeight={600}>{text}</Text>
                    </div>
                  </div>
                </div>
              </div>
              <div className="right">
                <div className="item">
                  <Text color={'infoGray'} small>{t('finance.total-earnings')}</Text>
                  <Text strong fontSize='1.4375rem'>{total && total} руб.</Text>
                </div>
                <div className="item">
                  <Text color={'infoGray'} small>{t('finance.available-earnings')}</Text>
                  <Text strong fontSize='1.4375rem'>{available && available} руб.</Text>
                </div>
              </div>
            </div>
          </WhiteContainer>

          <WhiteContainer>
            <div className="row">
              <div className="left">
                <Text strong fontSize='1.4375rem'>{t('finance.request')}</Text>
                <Text>{t('finance.all-fields-required')}</Text>
              </div>
              <div className="right">
                <Input
                  label={t('finance.card-number')}
                  value={userData.cardNumber}
                  showButtons={false}
                  onChange={(e) => handleChangeValue('cardNumber', e.target.value)}
                />
                <Input
                  label={t('finance.card-name')}
                  value={userData.cardOwnerName}
                  showButtons={false}
                  onChange={(e) => handleChangeValue('cardOwnerName', e.target.value)}
                />

                <Input
                  label={'Email'}
                  value={userData.email || ''}
                  onChange={(e) => handleChangeValue('email', e.target.value)}
                  showButtons={false}
                />

                <Input
                  label={t('profile.phoneNumber') || ''}
                  value={userData.phoneNumber || ''}
                  onChange={(e) => handleChangeValue('phoneNumber', e.target.value)}
                  type={'tel'}
                  showButtons={false}
                />

                <Button
                  className={`request-button ${(available !== null && available <= 0) || isSent ? 'inactive' : ''}`}
                  disabled={disabled}
                  btnStyle='primary'
                  onClick={handleSendRequest}
                >
                  {isLoading ? <CircleLoader /> : buttonText}
                </Button>

                {isSent && status === RequestStatusType.IN_PROCESS && (<Text style={{ color: 'rgba(0,0,0,0.8)' }} fontSize='0.75rem'>{t('finance.request-sent')}</Text>)}
              </div>
            </div>

          </WhiteContainer>
        </div>

        <div className="column">
          <WhiteContainer style={{ minHeight: '100%' }}>
            <Text strong fontSize='1.4375rem'>{t('finance.history')}</Text>
            <div className="earnings">
              <>
                <Text strong>{t('finance.date')}</Text>
                <Text strong>{t('finance.amount')}</Text>
              </>

              {earnings.length > 0 && earnings.map((item: any) => (
                <Fragment key={item.createdDate} >
                  <Text>{new Date(item.createdDate).toLocaleDateString()}</Text>
                  <Text>{item.amount} руб.</Text>
                </Fragment>
              ))}

              {earnings.length === 0 && <Text color='infoGray' fontWeight={600}>{t('finance.no-earnings')}</Text>}
            </div>
          </WhiteContainer>
        </div>
      </div>
    </Wrapper>

  )
}

export default Finance