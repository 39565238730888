import React, { ButtonHTMLAttributes } from 'react';
import styled, { css } from 'styled-components';

interface IButtonProps extends ButtonHTMLAttributes<any> {
  icon?: string;
  children?: any;
  btnStyle?: string;
  active?: boolean;
  iconPosition?: 'right' | 'left';
}

const ButtonWrapper = styled.button<IButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.8125rem 1.25rem;
  background-color: ${(p) => p.theme.primary};
  color: ${(p) => p.theme.white};
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.25);
  border-radius: 1rem;
  border: 0;
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5em;
  cursor: pointer;
  transition: background-color 250ms linear, color 250ms linear;
  &:hover {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
      ${(p) => p.theme.primary};
  }
  &:disabled {
    background-color: ${(p) => p.theme.lightGray2};
    color: rgba(0, 0, 0, 0.2);
  }
  & span[class^='icon-'],
  span[class*=' icon-'] {
    margin-left: 0.5rem;
    font-size: 1.25rem;
    order: ${(p) => (p.iconPosition === 'left' ? -1 : 1)};
  }

  ${(p) =>
    p.btnStyle === 'inverse' &&
    css`
      background-color: ${p.theme.white};
      color: ${p.theme.primary};
      &:hover {
        background-color: linear-gradient(0deg, rgba(102, 32, 199, 0.4), rgba(102, 32, 199, 0.4)),
          ${p.theme.white};
      }
    `}

  ${(p) =>
    p.btnStyle === 'cancel' &&
    css`
      background-color: ${p.theme.white};
      color: ${p.theme.black};
      box-shadow: none;
      &:hover {
        background: none;
        color: ${p.theme.primary};
      }
      &:disabled {
        background: none;
        color: rgba(0, 0, 0, 0.15);
      }
    `}

  ${(p) =>
    p.btnStyle === 'gray' &&
    css`
      background: ${p.active ? p.theme.lightPrimary : p.theme.lightGray2};
      color: ${p.active ? p.theme.primary : p.theme.black};
      box-shadow: none;
      padding: 0.75rem 1rem;
      &:hover {
        background: ${p.theme.lightPrimary};
        color: ${p.theme.primary};
      }
    `}
`;

export const Button = ({ icon, children, iconPosition = 'right', ...rest }: IButtonProps) => {
  return (
    <ButtonWrapper type="button" iconPosition={iconPosition} {...rest}>
      {children}
      {icon && <span className={`icon-${icon}`} />}
    </ButtonWrapper>
  );
};
