import React, { useEffect, useState } from 'react'
import { GraphData, GraphDataItem, PartnerLink, PeriodType } from '../../interfaces/partner.interace';

import Chart from '../../components/chart.component';
import LinksList from '../../components/links-list.component';
import { getGraphData, getLinkList } from '../../requests/partner.request';

const colors = ['#C72020', '#EEDA25', '#23BCDE', '#6620C7', '#D8EC62', '#E58C23', '#2ABE1D', '#2045C7', '#20C7B3', '#D500F9']

const Links = () => {
  const [links, setLinks] = useState<PartnerLink[]>([]);
  const [data, setData] = useState<any>(null);
  const [total, setTotal] = useState(0);
  const [graphType, setGraphType] = useState('REGISTRATION');

  const formatData = (data: GraphData) => {
    const formatted = Object.values(data)
      .map((item: GraphDataItem[]) => item.map((elem: GraphDataItem) => ({ ...elem, label: `${new Date(elem.startDate).toLocaleDateString().slice(0, -5)}-${new Date(elem.endDate).toLocaleDateString()}` })))
      .flatMap((item: any) => item)

    const labels = Array.from(new Set(formatted.sort((a: any, b: any) => a.startDate - b.startDate).map((item: any) => item.label)))

    const datasets = links.map((link: PartnerLink, index: number) => ({
      label: link.name,
      data: labels.map((label: string) => formatted.find((item: any) => item.label === label && item.linkName === link.name)?.count || 0),
      backgroundColor: colors[index],
      barThickness: 8,
      borderWidth: 2,
      borderColor: 'transparent',
    }))

    const res = {
      labels,
      datasets
    }
    
    setData(res)
  }

  const updateList = () => {
    getLinkList()
      .then((res: any) => {
        setLinks(res.list.sort((a: any, b: any) => a.id - b.id))
      })
  }

  const getData = (graphType: string, periodType: PeriodType, start?: Date | null, end?: Date | null) => {
    getGraphData(graphType, periodType, start, end).then((res: any) => {
      formatData(res.data.periodInfo)
      setTotal(res.data.totalCount);
    })
  }

  useEffect(() => {
    updateList();
  }, [])

  useEffect(() => {
    getData(graphType, 'BY_WEEK')
  }, [links])

  return (<>
    <LinksList
      links={links}
      updateList={updateList}
    />
    <Chart
      data={data}
      getData={getData}
      total={total}
      graphType={graphType}
      setGraphType={setGraphType}
    />
  </>)
}

export default Links