import React, { ChangeEvent, FC, MouseEventHandler, useEffect, useRef, useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import ru from 'date-fns/locale/ru';
import 'react-datepicker/dist/react-datepicker.css';
import { PeriodType } from '../interfaces/partner.interace';
import styled, { css } from 'styled-components';
import { Button } from './button.component';
import { useTranslation } from 'react-i18next';
registerLocale('ru', ru)

const SelectWrapper = styled.div<{ isActive?: boolean }>`
  display: flex;
  padding: 0.375rem 0.625rem;
  align-items: center;
  gap: 0.375rem;
  border-radius: 0.5rem;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.10) 0%, rgba(0, 0, 0, 0.10) 100%), #FFF;
  white-space: nowrap;
  width: max-content;
  cursor: pointer;
  position: relative;
  font-size: 0.875rem;

  ${({ isActive }) => isActive && css`
    background: rgba(102, 32, 199, 0.40);
    color: ${p => p.theme.primary};
  `}

  .react-datepicker {
    border-radius: 1rem;
    background: #FFF;
    box-shadow: 0px 6px 20px 0px rgba(0, 0, 0, 0.15);
    padding: 0.9375rem;
    border: none;

    &__navigation {
      top: 0.9375rem;
    }

    &__month-container {
      float: none;
    }

    &__header {
      background: #FFF;
      display: flex;
      flex-direction: column;
      gap: 0.9375rem;
      padding: 0;
      border: none;

      &__dropdown {
        display: none;
      }
    }

    &__day-name {
      text-transform: uppercase;
      color: ${p => p.theme.infoGray};
      font-size: 0.875rem;
      font-weight: 600;
    }

    &__day {
      font-size: 0.875rem;
      font-weight: 600;
      border-radius: 0.5rem;
      
      &--outside-month {
        color: ${p => p.theme.infoGray};
      }

      &--in-range,
      &--in-selecting-range {
        background: rgba(0, 0, 0, 0.10);
        color: ${p => p.theme.black};
      }

      &--range-start,
      &--range-end,
      &--keyboard-selected,
      &--selecting-range-start,
      &--selecting-range-end{
        background: rgba(102, 32, 199, 0.40);
        color: ${p => p.theme.black};
      }
    }

    &__current-month {
      font-family: Manrope;
      font-size: 1rem;
      font-weight: 700;
      text-transform: capitalize;
    }
  
    &__children-container {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      margin: 0;
  
      button{
        width: 100%;
      }
    }
  }
`;

const OptionsWrapper = styled.div`
  position: absolute;
  border-radius: 1rem;
  background: #FFF;
  box-shadow: 0px 6px 20px 0px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  width: max-content;
`;

const StyledOption = styled.div`
  width: 100%;
  height: 2.5rem;
  padding: 0.625rem 0.9375rem;
  border-bottom: 1px solid ${p => p.theme.gray15};
  cursor: pointer;  
  font-size: 0.875rem;
  font-weight: 600;
  color: ${p => p.theme.black};
  
  &:first-child {
    border-radius: 1rem 1rem 0 0;
  }

  &:last-child {
    border-radius: 0 0 1rem 1rem;
  }
  
  &:hover {
    background: rgba(102, 32, 199, 0.40);
    color: ${p => p.theme.primary};
  }
`;

const AbsoluteWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: -0.625rem;
  left: 50%;
  transform: translate(-50%, 100%);
`;

const options: PeriodType[] = ['BY_WEEK', 'BY_MONTH', 'BY_PERIOD']

interface IProps {
  selectedOption: PeriodType;
  startDate: Date | null;
  endDate: Date | null;
  handleOptionChange: (e: any) => void;
  handleDateChange: (dates: [Date | null, Date | null]) => void;
  getData: (graphType: string, periodType: PeriodType, start?: Date | null, end?: Date | null) => void;
  graphType: string;
}

const ChartDate: FC<IProps> = ({
  selectedOption,
  startDate,
  endDate,
  handleOptionChange,
  handleDateChange,
  getData,
  graphType
}) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const [dates, setDates] = useState<string>('');

  const containerRef = useRef<HTMLDivElement>(null);
  const calRef = useRef(null);

  const handleSelect = (e: ChangeEvent<HTMLDivElement>, option: PeriodType) => {
    e.stopPropagation();
    handleOptionChange(option);
    setIsOpen(false);
    setIsDatePickerOpen(option === 'BY_PERIOD');
    setDates('');
  }

  const handleConfirm = (e: any) => {
    e.preventDefault();
    handleDateChange([startDate, endDate]);
    setDates(`${startDate?.toLocaleDateString()} - ${endDate?.toLocaleDateString()}`)
    setIsDatePickerOpen(false);
    getData(graphType, selectedOption, startDate, endDate);
  }

  const handleClearDate = () => {
    handleDateChange([null, null]);
    setDates('');
  }

  const isRangeChosen = () => {
    return selectedOption === 'BY_PERIOD' && startDate !== null && endDate && endDate !== null ? true : false
  }

  useEffect(() => {
    if (!selectedOption) {
      handleOptionChange(options[0]);
    }

    document.addEventListener('click', (e: any) => {
      if (!containerRef.current?.contains(e.target)) {
        setIsOpen(false);
        setIsDatePickerOpen(false);
      }
    })

    return () => {
      document.removeEventListener('click', (e: any) => {
        if (!containerRef.current?.contains(e.target)) {
          setIsOpen(false);
          setIsDatePickerOpen(false);
        }
      })
    }

  }, [])

  return (
    <>
      <SelectWrapper onClick={() => setIsOpen(!isOpen)} ref={containerRef} isActive={isRangeChosen() && dates.length > 0}>
        {isRangeChosen() && dates.length > 0 ? dates : t(`filter.${selectedOption}`)}

        {isRangeChosen() && dates.length > 0 ? <span className='icon-close' onClick={handleClearDate} /> : <span className='icon-chevron-down' />}

        {
          isOpen && (
            <AbsoluteWrapper>
              <OptionsWrapper>
                {options.map(option => (
                  <StyledOption onClick={(e: any) => handleSelect(e, option)} key={option}>
                    {t(`filter.${option}`)}
                  </StyledOption>
                ))}
              </OptionsWrapper >
            </AbsoluteWrapper >
          )
        }

        {
          isDatePickerOpen && (
            <AbsoluteWrapper>
              <DatePicker
                onChange={handleDateChange}
                startDate={startDate}
                endDate={endDate}
                selectsRange
                inline
                locale={ru}
                onClickOutside={() => setIsDatePickerOpen(false)}
                ref={calRef}
              >
                <Button type="submit" onClick={handleConfirm} disabled={!startDate || !endDate}>
                  {t('button.ready')} <span className="icon-check" />
                </Button>

              </DatePicker>
            </AbsoluteWrapper>
          )
        }

      </SelectWrapper >

    </>

  );
};

export default ChartDate;
