const chartData = (labels: string[], datasets: { label: string, data: number[] }[]) => ({
  labels,
  datasets,
});

const chartOptions: any = {
  maintainAspectRatio: false,
  aspectRatio: 0.5,
  scales: {
    x: {
      color: 'red',
      grid: {
        display: false,
      },
      ticks: {
        display: true,
        color: 'black',
        font: {
          size: 14,
          weight: 600,
          family: 'Manrope'
        },
      },
      border: {
        display: false
      }
    },
    y: {
      color: 'transparent',
      grid: {
        display: false,
      },
      ticks: {
        display: true,
        color: 'black',
        font: {
          size: 14,
          weight: 600,
          family: 'Manrope'
        },
      },
      border: {
        display: false
      },
    },
  },
  plugins: {
    title: {
      display: false,
    },
    legend: {
      display: false,
      position: 'bottom',
      labels: {
        boxWidth: 20,
        boxHeight: 20,
        usePointStyle: true,
        pointStyle: 'circle',
        color: 'black',
        font: {
          size: 14,
          weight: 600,
          family: 'Manrope',
        },
      },
    },
    tooltip: {
      titleColor: "lightgray",
      titleFont: {
        family: 'Manrope',
        size: 14,
        weight: 600
      },
      titleSpacing: 5,
      bodyAlign: 'center',
      bodyColor: "#fff",
      bodyFont: {
        family: 'Manrope',
        size: 14,
        weight: 600
      },
      displayColors: false,
      callbacks: {
        label: (context: any) => {
          const value = context.raw || 0;
          return value;
        },
        title: (context: any) => {
          const label = context[0].dataset.label;
          return label;
        }
      },
    }
  },
}

export {
  chartOptions,
  chartData
}