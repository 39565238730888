import styled from 'styled-components';
import { Headline } from '../../components/headline.component';

export const Wrapper = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;

  .df {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
    align-items: center;
  }

  & > ${Headline} {
    color: ${(p) => p.theme.white};
  }
  & > ${Headline} {
    margin-bottom: 0.625rem;
  }
`;

export const SparkleWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  margin-top: 1.875rem;
`;

const ColumnTemplate = styled.div`
  position: relative;
  min-height: 1px;
  display: grid;
`;

export const RightColumn = styled(ColumnTemplate)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex: 0 0 40%;
  padding: 2rem;
  background: ${(p) =>
    `linear-gradient(0deg, ${p.theme.primary}, ${p.theme.primary}), ${p.theme.white}`};
`;

export const LeftColumn = styled(ColumnTemplate)`
  flex: 0 0 100%;
  max-width: 100%;
  justify-content: center;
  align-content: center;
 
  & > img {
    position: absolute;
    top: 13px;
    left: 20px;
    height: 3.75rem;
    filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.25));
  }
`;

export const WhiteBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #ffffff;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
  border-radius: 16px;
  width: 380px;
  height: max-content;
  padding: 20px;
  gap: 10px;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  gap: 20px;
  width: 100%;
`;
export const DoneWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

export const PrivacyPolicy = styled.div`
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.1875rem;
  color: ${(p) => p.theme.black};
  margin-top: -10px;
  & a {
    color: ${(p) => p.theme.primary};
    cursor: pointer;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
`;

const components = {
  Wrapper,
  LeftColumn,
  Form,
  DoneWrapper,
  PrivacyPolicy,
};

export default components;
